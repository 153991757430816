import React, { memo } from 'react';
import { useRoutes } from 'react-router-dom';
import NotFound from '../screens/NotFound';
import appRoutes from './appRoutes';
// import practiceAccountRoutes from './practiceAccountRoutes';
import authRoutes from './authRoutes';
import offlineRoutes from './offlineRoutes';

const Route = () => {
  const element = useRoutes([
    ...authRoutes,
    ...appRoutes,
    ...offlineRoutes,
    // ...practiceAccountRoutes,
    { path: '*', element: <NotFound /> },
  ]);

  return element;
};

Route.propTypes = {};

export default memo(Route);
