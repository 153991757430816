/* eslint-disable */
import React, { lazy /* , useEffect */ } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader/Loader';
import MainLogo from '../components/MainLogo/MainLogo';
import Cookies from 'js-cookie';
// import setPassword from '../screens/Auth/SetPassword/SetPassword';

const Auth = lazy(() => import('../screens/Auth'));
const SetEmail = lazy(() => import('../screens/Auth/setEmail/SetEmail'));
// const Login = lazy(() => import('../screens/Auth/Login'));
const SetPassword = lazy(() => import('../screens/Auth/SetPassword'));
const CreateAccount = lazy(() => import('../screens/Auth/CreateAccount'));
const ForgotPassword = lazy(() => import('../screens/Auth/ForgotPassword'));
const VerifyPassword = lazy(() => import('../screens/Auth/VerifyPassword'));
const CreatePassword = lazy(() => import('../screens/Auth/CreatePassword'));
const CreateAccountPassword = lazy(() => import('../screens/Auth/CreateAccountPassword'));
const CreateOtherInfo = lazy(() => import('../screens/Auth/CreateOtherInfo'));
const SledLogin = lazy(() => import('../screens/Auth/SledLogin'));
const AdminImitatorLogin = lazy(() =>
  import('../screens/Auth/AdminImitatorLogin/AdminImitatorLogin'),
);
const StudentApplicationForm = lazy(() => import('../screens/Main/v1/StudentApplicationForm'));
const StudentFormSuccess = lazy(() => import('../screens/Main/v1/StudentApplicationForm/Success'));
const Success = lazy(() => import('../screens/Main/v1/StudentApplicationForm/Success'));
const ProposalSubmission = lazy(() =>
  import('../screens/Main/v1/LandingPage/ProposalSubmission/ProposalSubmission'),
);
const LoginViaSalesforce = lazy(() => import('../screens/Auth/LoginViaSalesforce'));

const PublicRoute = ({ as: Component, ...props }) => {
  // const [cookies] = useCookies();
  const navigate = useNavigate();
  const [auth, setAuth] = React.useState(false);
  const [salesforceAuth, setSalesforceAuth] = React.useState(false);

  React.useEffect(() => {
    if (Cookies.get('userId') && window.location.href.indexOf('/loginViaSalesforce') === -1) {
      // navigate(`app/dashboard/${Cookies.get('School__c__ID')}`);
      navigate(`landing-page`);
    } else if (window.location.href.indexOf('/loginViaSalesforce') !== -1) {
      setSalesforceAuth(true);
    } else {
      setAuth(false);
    }
  }, [navigate]);

  return salesforceAuth ? <LoginViaSalesforce /> : <Component {...props} />;
};

const authNav = [
  {
    path: '',
    element: <SledLogin />,
  },
  {
    path: 'forgotpassword',
    element: <ForgotPassword />,
  },
  {
    path: 'createAccount',
    element: <CreateAccount />,
  },
  {
    path: 'password',
    element: <SetPassword />,
  },
  {
    path: 'createpassword',
    element: <CreatePassword />,
  },
  {
    path: 'createaccountpassword',
    element: <CreateAccountPassword />,
  },
  {
    path: 'createOtherInfo',
    element: <CreateOtherInfo />,
  },
  {
    path: 'api/verifytoken/:token/:Id',
    element: <VerifyPassword />,
  },
  {
    path: 'login',
    element: <SledLogin />,
  },
  {
    path: 'student-application-form/success',
    element: <StudentFormSuccess />,
  },
  {
    path: 'student-application-form/:schoolId',
    element: <StudentApplicationForm />,
  },
  {
    name: 'proposalsubmission',
    path: 'proposal-submission/:schoolId',
    element: <ProposalSubmission />,
  },
  {
    path: 'proposal-submission-form/success',
    element: <Success />,
  },
  {
    path: 'adminImitatorLogin',
    element: <AdminImitatorLogin />,
  },
];

const authRoutes = [
  {
    path: '/',
    element: <PublicRoute as={Auth} />,
    children: authNav,
  },
];

export default authRoutes;
