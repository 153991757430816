/* eslint-disable */

import React, { lazy } from 'react';

const ProjectSubmission = lazy(() =>
  import('../screens/Main/v1/LandingPage/ProjectSubmission/ProjectSubmission'),
);

const EditProject = lazy(() =>
  import('../screens/Main/v1/LandingPage/ProjectSubmission/EditProject'),
);
// const ProposalSubmission = lazy(() =>
//   import('../screens/Main/v1/LandingPage/ProposalSubmission/ProposalSubmission'),
// );
const RankingDetails = lazy(() => import('../screens/Main/RankingDetails'));
// const EpisodePage = lazy(() => import('../screens/Main/EpisodePage'));
const Dashboard = lazy(() => import('../screens/Main/Dashboard'));
// const UserProfile = lazy(() => import('../screens/Main/UserProfile'));
const BadgesDetails = lazy(() => import('../screens/Main/BadgesDetails'));
const NewProject = lazy(() => import('../screens/Main/v1/NewProject/NewProject'));
const LandingPage = lazy(() => import('../screens/Main/LandingPage'));
const LandingPagev1 = lazy(() => import('../screens/Main/v1/LandingPage'));
const LandingScreen = lazy(() => import('../screens/Main/v1/LandingPage/LandingScreen'));
const ShareApplication = lazy(() =>
  import('../screens/Main/v1/LandingPage/StudentModal/ShareApplication'),
);
const AddStudentsByCSV = lazy(() =>
  import('../screens/Main/v1/LandingPage/StudentModal/AddStudentsByCSV'),
);

const LoginViaSalesforce = lazy(() => import('../screens/Auth/LoginViaSalesforce'));
// const Success = lazy(() => import('../screens/Main/v1/StudentApplicationForm/Success'));

// const SeasonChildRoutes = [
//   {
//     name: 'playSeason',
//     path: 'seasons/play',
//     element: <PlayEpisode />,
//   },
//   // {
//   //   name: 'season',
//   //   path: 'season',
//   //   element: <Season />,
//   //   // children:
//   // },

// ];

export const ChildRoutes = [
  {
    name: 'dashboard',
    path: 'school/:schoolId',
    element: <LandingPagev1 />,
    // children: SeasonChildRoutes
  },
  {
    name: 'dashboard',
    path: 'school/:schoolId/:currentTab',
    element: <LandingPagev1 />,
    // children: SeasonChildRoutes
  },
  {
    name: 'dashboard',
    path: 'school/:schoolId/projects/:currentTab',
    element: <LandingPagev1 />,
    // children: SeasonChildRoutes
  },
  // {
  //   name: 'dashboard',
  //   path: 'dashboard/:schoolId',
  //   element: <Dashboard />,
  //   // children: SeasonChildRoutes
  // },
  {
    name: 'rankingdetails',
    // path: 'dashboard/:schoolId/rankingdetails/:id',
    path: 'school/:schoolId/rankingdetails/:id',
    element: <RankingDetails />,
  },
  {
    name: 'projectsubmission',
    // path: 'dashboard/:schoolId/rankingdetails/:id',
    path: 'add-new-project/:urlschoolid',
    element: <ProjectSubmission />,
  },
  {
    name: 'projectEdit',
    // path: 'dashboard/:schoolId/rankingdetails/:id',
    path: 'add-new-project/edit/:urlschoolid/:projectId/:currentTab',
    element: <EditProject />,
  },
  // {
  //   name: 'proposalsubmission',
  //   path: 'proposal-submission/:schoolId',
  //   element: <ProposalSubmission />,
  // },
  // {
  //   name: 'userProfile',
  //   path: 'userprofile',
  //   element: <UserProfile />,
  // },
  // {
  //   name: 'seasonEpisode',
  //   path: 'season/episode',
  //   element: <EpisodePage />,
  // },
  {
    name: 'badgedetails',
    path: 'school/:schoolId/badgedetails/:id',
    element: <BadgesDetails />,
  },
  {
    name: 'newProject',
    path: 'school/:schoolId/newProject/:urlprojectId',
    element: <NewProject />,
  },
  {
    name: 'landingPage',
    path: 'landing-page-old',
    element: <LandingPage />,
  },
  {
    name: 'landingPage',
    path: 'landing-page',
    element: <LandingScreen />,
  },
  {
    name: 'LandingScreen',
    path: 'landing-screen',
    element: <LandingPagev1 />,
  },
  {
    name: 'ShareApplication',
    path: 'share-application',
    element: <ShareApplication />,
  },
  {
    name: 'AddStudentsByCSV',
    path: 'add-students-csv',
    element: <AddStudentsByCSV />,
  },
  // {
  //   path: 'proposal-submission-form/success',
  //   element: <Success />,
  // },
  {
    name: 'Login Via Salesforce',
    path: 'loginViaSalesforce',
    element: <LoginViaSalesforce />,
  },
];

export default ChildRoutes;
