/* eslint-disable*/
import { createMuiTheme } from '@material-ui/core/styles';
import { size } from 'lodash';

const theme = createMuiTheme({
  // Color style
  palette: {
    primary: {
      main: '#0F75BB',
      contrastText: '#fff',
    },
    secondary: {
      main: '#0F75BB',
      contrastText: '#a29c99',
    },
    tertiary: {
      main: '#61B3CD',
      contrastText: '#fff',
    },
  },
  // Fonts style
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    h1: {
      fontSize: 24,
      fontWeight: 400,
      color: '#616569',
      wordBreak: 'break-word',
    },
    h2: {
      fontSize: 20,
      fontWeight: 800,
      color: '#333',
      wordBreak: 'break-word',
    },
    h3: {
      fontSize: 21,
      fontWeight: 400,
      color: '#33AFE7',
      wordBreak: 'break-word',
    },
    h4: {
      fontSize: 20,
      fontWeight: 400,
      color: '#4B403A',
      wordBreak: 'break-word',
    },
    h5: {
      fontSize: 16,
      fontWeight: 700,
      color: '#333',
      wordBreak: 'break-word',
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
      color: '#313234',
      wordBreak: 'break-word',
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      color: '#616569',
      wordBreak: 'break-word',
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
      color: '#92979d',
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 700,
      color: '#92979d',
    },
  },
  // Button style
  // forms style
  // Overrides style - button, forms

  overrides: {
    breakpoints: {
      values: {
        xs: 0,
        sm: 767,
        md: 1000,
        lg: 1024,
        xl: 1920,
      },
    },

    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: '#F88700',
      },
    },

    MuiPopover: {
      backgroundColor: 'transparent',
      borderRadius: '8px',
    },

    MuiListItemIcon: {
      root: {
        minWidth: 'auto',
        color: '#fff',
      },
    },
    MuiListItemText: {
      root: {
        width: '85px',
        textAlign: 'center',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
      },
      primary: {
        fontSize: '12px',
        color: '#fff',
        lineHeight: '14px',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#F7F6F5',
        paddingLeft: '0px',
        paddingRight: '0px',
        color: '#4B403A',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0px',
        paddingBottom: '0px',
        margin: '0',
      },
    },
    MuiListItem: {
      root: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      gutters: {
        paddingLeft: '12px',
        paddingRight: '12px',
      },
      button: {
        '&:hover': {
          backgroundColor: '#0F75BB !important',
          borderRadius: '4px',
          color: '#fff',
          '& .MuiListItemIcon-root': {
            color: '#fff',
          },
          '& .MuiListItemText-primary': {
            color: '#fff',
          },
        },
        '&:active': {
          background: '#0F75BB',
          borderRadius: '8px',
          '& .MuiListItemIcon-root': {
            color: '#fff',
          },
          '& .MuiListItemText-primary': {
            color: '#fff',
          },
        },
      },
    },

    MuiDrawer: {
      paperAnchorDockedLeft: {
        /* borderRight: '10px solid rgba(243, 239, 234, 1)', */
        boxShadow: '3px 0 5px 0 rgba(0,0,0,0.1)',
      },
    },

    MuiDivider: {
      root: {
        backgroundColor: '#C2C9D1',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#f9fafa',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
        fontSize: 14,
        fontWeight: 400,
        textTransform: 'normal',
        color: '#FFFFFF',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      outlinedPrimary: {
        background: '#fff',
      },
      outlinedSecondary: {
        color: '#616569',
        border: '1px solid #616569',
        '&:hover': {
          color: '#616569',
          border: '1px solid #616569',
        },
      },
      /* root: {
        borderRadius: 8,
        padding: '11px 35px',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1,
        minWidth: 'auto',
        textTransform: 'normal',
        color: '#FFFFFF',
        '@media (max-width:960px)': {
          borderRadius: 10,
          padding: '12px 5px',
          fontSize: 14,
        },
      }, */
    },
    MuiButtonBase: {
      root: {
        borderRadius: 5,
        padding: '14px 35px',
        fontSize: 16,
        fontWeight: 700,
        lineHeight: 1,
        minWidth: 'auto',
        '@media (max-width:960px)': {
          borderRadius: 10,
          padding: '12px 25px',
          fontSize: 14,
        },
      },
      contained: {
        color: '#FFFFFF',
        backgroundColor: 'rgba(162, 156, 153, 1)',
        minWidth: '120px',
      },
    },
    /* MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: 'rgba(72, 96, 207, 0.1)',
          color: '#3C3F61',
        },
        '&:active': {
          backgroundColor: 'rgba(72, 96, 207, 0.1)',
          color: '#3C3F61',
        },
      },
      gutters: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    }, */
    MuiInputLabel: {
      root: {
        fontSize: 16,
        lineHeight: 1.5,
        '&.$Mui-disabled': {
          color: '#000',
        },
      },
      shrink: {
        fontSize: 18,
        color: '#0F75BB',
        fontWeight: 400,
      },
    },
    /* MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#ffffff!important',
          
        },
      },
    }, */

    MuiOutlinedInput: {
      root: {
        /* border: '2px solid rgba(162,156,153,0.2)', */
        borderRadius: 4,
        backgroundColor: 'rgba(255,255,255,0.8)',
      },
      inputMultiline: {
        borderRadius: 15,
        padding: 15,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
      },
      multiline: {
        padding: 0,
      },
      notchedOutline: {
        borderColor: 'transparent !important',
      },
    },

    MuiAutocomplete: {
      option: {
        '&:hover': {
          backgroundColor: '#ffffff',
        },
        '&$checked': {
          backgroundColor: '#4B403A',
        },
      },
    },

    // Mui: {
    //   Disabled: {
    //     fontSize: 56,
    //     backgroundColor: '#000',
    //   },
    // },

    MuiInputBase: {
      inputMarginDense: {
        paddingBottom: 8,
        paddingTop: 8,
      },
      input: {
        color: '#616569',
      },
      /* input: {
        fontSize: 16,
        color: '#4B403A',
        backgroundColor: '#fff',
        borderRadius: 15,
        fontWeight: 400,
        padding: 10,
        '&.$Mui-disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
      }, */
      multiline: {
        // border: '2px solid rgba(162,156,153,0.2)',
        // borderBottom: 'none',
        fontSize: 16,
        color: '#4B403A',
        backgroundColor: '#fff',
        borderRadius: 15,
        fontWeight: 400,
        // '&.$Mui-disabled': {
        //   backgroundColor: 'rgba(0, 0, 0, 0.1)',
        // },
      },
    },
    /* MuiInput: {
      underline: {
        '&&&&:before': {
          borderBottom: '0px solid rgba(187, 187, 187, 1)',
        },
        '&&&&:after': {
          borderBottom: '0px solid rgba(187, 187, 187, 1)',
        },
      },
    }, */
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
        fontWeight: 400,
        color: '#616569',
      },
    },
    // MuiDialogContent: {
    //   root: {
    //     overflow: 'auto',
    //   },
    // },
    MuiMenu: {
      list: {
        background: '#fff',
      },
    },

    MuiSelect: {
      icon: {
        fontSize: '30px',
        color: '#F88700',
        right: '5px',
        top: 'calc(50% - 16px)',
      },
      outlined: {
        borderRadius: 0,
      },
      select: {
        border: '2px solid #eaeaeb',
        borderRadius: '5px',
        borderRadius: 5,
        padding: '9px 10px',
        border: 'none',
        boxShadow: 'none',
        '&:focus': {
          borderRadius: 5,
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: '#61B3CD',
      },
    },
    MuiIconButton: {
      root: {
        color: '#92979d',
        padding: '12px',
      },
      /* colorInherit: {
        color: '#0F75BB',
      },
      colorSecondary: {
        color: '#ffffff',
      }, */
      edgeStart: {
        '@media (max-width:1279px)': {
          marginLeft: 0,
        },
      },
    },

    MuiAvatar: {
      img: {
        width: 'auto',
        height: 'auto',
      },
    },

    // Right Panel
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: '#4B403A',
        color: '#ffffff',
      },
    },

    MuiExpansionPanelDetails: {
      root: {
        padding: '10px 0px 10px 0px',
      },
    },

    // Datepicker
    MuiPickersBasePicker: {
      pickerView: {
        width: 'auto',
        height: 'auto',
        maxWidth: 'auto',
        minWidth: 'auto',
        minHeight: 'auto',
        padding: '10px 20px 10px 20px',
      },
    },

    MuiPickersCalendarHeader: {
      switchHeader: {
        marginTop: '15px',
        marginBottom: '30px',
      },
    },

    // Chip

    MuiChip: {
      root: {
        boxShadow: '0px 0 5px rgba(0,0,0,0.3)',
      },
      sizeSmall: {
        fontSize: '16px',
      },
    },

    // Switch
    /* MuiSwitch: {
      root: {
        width: 41,
        height: 22,
        padding: 0,
        display: 'flex',
      },

      switchBase: {
        padding: 4,
        color: '#A29C99',
        '&$checked': {
          transform: 'translateX(20px)',
          color: '#FF7412!important',

          '& + $track': {
            opacity: 1,
            backgroundColor: '#fff!important',
            borderColor: '#FF7412',
            color: '#FF7412!important',
          },
        },
      },

      thumb: {
        width: 13,
        height: 13,
        boxShadow: 'none',
      },

      track: {
        border: '2px solid #A29C99',
        borderRadius: 30 / 2,
        opacity: 1,
        backgroundColor: '#fff',
      },
    }, */

    // Overrides style - modal dialog
    MuiDialog: {
      paper: {
        backgroundColor: '#fff',
      },
    },

    MuiDialogTitle: {
      root: {
        /* margin: 0,
        padding: '16px',
        textAlign: 'center', */
      },
    },

    MuiDialogContent: {
      root: {
        overflow: 'auto',
        paddingTop: '0px',
        paddingBottom: '16px',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },

    MuiDialogActions: {
      spacing: {
        paddingTop: '16px',
        paddingBottom: '30px',
        paddingLeft: '16px',
        paddingRight: '16px',
        justifyContent: 'center',
      },
    },

    // Chat

    // Overrides style - material ui data table

    MuiTableCell: {
      root: {
        borderBottom: '1px solid #f0f2f3',
        borderRight: '1px solid #f0f2f3',
        wordBreak: 'break-word',
        padding: '12px',
        '&:last-child': {
          borderRight: 'none',
          width: '96px!important',
          textAlign: 'center',
        },
      },

      head: {
        padding: '22px 16px 4px !important',
        backgroundColor: '#f9fafa !important',
        color: '#000',
        fontWeight: '700',
        fontSize: 12,
        whiteSpace: 'nowrap',
        borderBottom: '1px solid #616569',
      },

      body: {
        color: '#92979d',
      },
    },

    MuiTableRow: {
      root: {
        backgroundColor: '#FFFFFF',
        '&:hover': {
          backgroundColor: '#F1F1F1',
        },
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 'none',
          },
        },
      },
      footer: {
        backgroundColor: '#f9fafa',
        '&:hover': {
          backgroundColor: '#f9fafa',
        },
      },
    },

    MuiTablePagination: {
      select: {
        backgroundColor: 'transparent',
        border: '0px solid #c4c4c4',
        boxShadow: '0px 0px 0px rgba(162,156,153,0.7)',
        lineHeight: '20px',
      },

      root: {
        '&:last-child': {
          width: '100%!important',
        },
      },
    },

    /* MuiToolbar: {
      gutters: {
        padding: '0 !important',
      },
    }, */

    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
      },
      tooltipPlacementTop: {
        margin: '10px 0 !important',
      },
    },
  },
});

export default theme;
